import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { navigate } from "@reach/router";
import Layout from '../layout/layout';
import shareicon from '../assets/images/ic-share-black.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import CF from '../components/commonfunctions';
import ReadMoreAndLess from 'react-read-more-less';
import moment from "moment";
import Pagination from 'rc-pagination';
import Modal from 'react-bootstrap/Modal';
import ScaleLoader from "react-spinners/ScaleLoader";
import Search from '../components/search';
import { s3_url} from '../config/configs'
export const query = graphql`
{
  WordPress {
    menus {
      nodes {
        name
        slug
        id
        menuItems {
          nodes {
            label
            url
            cssClasses
            drawer_menu_apps {
              labeldrawer
              idmenudrawer
              backgroundColorIcon
            }
            menu_data {
              externalLink
              sectionId
              svg {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
}
`



const Resource = ({ data, ...props }) => {

    const [isSticky, setSticky] = useState(false);
    const [isOpen, setIsopen] = useState(false);
    const [scrollOffset, setOffset] = useState(0);
    const [viewAll, setViewAll] = useState(false);
    const [list, listData] = useState([]);
    const [total, newTotal] = useState();
    const [cat, setCat] = useState('');
    const [page, newPage] = useState(1);
    const [resoucePopUp, setresoucePopUp] = useState(false);
    const [mainMenu, mainMenuList] = useState([]);
    const [mainUrl, setMainUrl] = useState('');
    const [requestData, setRequestData] = useState([]);

    const handleScroll = (e) => {
        var headerH = document.querySelector('header').offsetHeight
        setSticky(window.scrollY > headerH ? true : false)
    };
    useEffect(() => {
        let token = CF.getItem('token');
        /*  if (!token) navigate('/login'); */

        let mainMenu = data && data.WordPress && data.WordPress.menus && data.WordPress.menus.nodes && data.WordPress.menus.nodes;
        mainMenuList(mainMenu);
        let url = props && props.location && props.location.search && (props.location.search).split('/');
        setMainUrl(url && url[0] ? url[0] : '');
        setCat(url && url[1] ? url[1] : '');
        resources(1, url && url[0], url && url[0] && url[1]);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);


    const filterApi = async (requestData) => {
        let resourcePlatform = []
        let resourceCategory = []
        requestData.forEach(element => {

          if(element.type === "platform"){
            resourcePlatform.push(parseInt(element.sectionId))
          }
          else {
            resourceCategory.push(parseInt(element.sectionId))
          }

        });

        let resourcedata = await fetch(s3_url+`/resources.json`)
        .then(response=>{
          return response.json()
        })
        var platform = resourcedata.filter(function(e) {
          return e.resource_platform.some(function(a) {
            return resourcePlatform.indexOf(a) != -1
          })
        })
        var category = resourcedata.filter(function(e) {
          return e.resource_categories.some(function(a) {
            return resourceCategory.indexOf(a) != -1
          })
        })
        var mainArray = [...platform, ...category]
        listData(mainArray)

      }
    const closeModal = () => {
        setresoucePopUp(false)
    }
    const resources = async (e, name, id) => {

        if (name === "?alerts" && id === '43') {
            let data = await fetch(s3_url+`/resources_43.json`)
            .then(response=>{
            return response.json()
            })
            listData(data)

        }
        if (name === "?releasenotes" && id === '48') {
            let data = await fetch(s3_url+`/resources_48.json`)
            .then(response=>{
            return response.json()
        })
        listData(data)
        }

    }

    const selecCheckBox = (key, type) => {
        let requestParamsData = [...requestData];
        const newMainMenu = mainMenu && mainMenu.length && mainMenu.map((name) => {
            if (name && name.id === "dGVybTo1Mg==" && type === "platform") {
                name.menuItems.nodes = name && name.menuItems && name.menuItems.nodes && name.menuItems.nodes.map((each, i) => {
                    const { sectionId } = each.menu_data;
                    if (i === key) {
                        each.checked = !each.checked;
                        if (each.checked) requestParamsData.push({ type: "platform", sectionId });
                        else requestParamsData = requestParamsData.filter(o => {
                            if (o.type === 'platform') {
                                if (o.sectionId !== sectionId) return o;
                            }
                            else return o;
                        });
                    }
                    return each;
                })
            }
            if (name && name.id === "dGVybTo1Mw==" && type === "category") {
                name.menuItems.nodes = name && name.menuItems && name.menuItems.nodes && name.menuItems.nodes.map((each, i) => {
                    const { sectionId } = each.menu_data;
                    if (i === key) {
                        each.checked = !each.checked;
                        if (each.checked) requestParamsData.push({ type: "category", sectionId });
                        else requestParamsData = requestParamsData.filter(o => {
                            if (o.type === 'category') {
                                if (o.sectionId !== sectionId) return o;
                            }
                            else return o;
                        });
                    }
                    return each;
                })
            }
            return name;
        });
        mainMenuList([...newMainMenu]);
        setRequestData(requestParamsData);
        filterApi(requestParamsData);
    }
    const handleCategory = (e, label, url, id) => {
        // if (label == "Search") {
        //     setIsopen(!isOpen);
        //     setresoucePopUp(false);
        // }
        // else if (label == "Filter") {
        //     setresoucePopUp(true);
        // }
        if (label == 'Subscribe') {
            e.preventDefault();
            e.stopPropagation();
            const scrollSec = document.querySelector(e.target.hash);
            const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
            const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
            setOffset(offsetH);
            setresoucePopUp(false);
            window.scrollTo({ behavior: "smooth", top: offsetH });
        }
        else if (label === 'Documentation') {
            navigate(url);
            setresoucePopUp(false);

        }
        else if (label === "Alerts") {
            navigate(`/resource?alerts/${id}`);
            resources(1, '?alerts', id);
            setViewAll(false);
            setMainUrl('?alerts');
        }
        else if (label === "Release Notes") {
            navigate(`/resource?releasenotes/${id}`);
            resources(1, '?releasenotes', id);
            setViewAll(false);
            setMainUrl('?releasenotes');
        }
        else if (label === "SQL Cookbook") {
            navigate(url);
            setresoucePopUp(false);
        }
    }
    return (
        <React.Fragment>
            {list && list.length && Array.isArray(list) ?
                <Layout uma="inner-page" headerColor="header-DS" title={"DS"} seoTitle={"Resources"}>
                    <div className={`category-menu-outer ds-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
                        <div className="container">
                            {/********************* Category Menu Start *********************/}
                            <nav className="category-menu">
                                <ul>
                                    {mainMenu && mainMenu.length && mainMenu.map((each, key) => {
                                        return (each && each.name === "Resources") &&
                                            each && each.menuItems && each.menuItems.nodes && each.menuItems.nodes.map((menu, key) => {
                                                if (menu && menu.label !== "Filter") {
                                                    return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label, menu.url, menu.menu_data && menu.menu_data.sectionId)}>
                                                        <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search" && "#"}>
                                                            <span className="category-img">
                                                                <img src={menu && menu.menu_data && menu.menu_data.svg && menu.menu_data.svg.sourceUrl} alt={menu.label} />
                                                            </span>
                                                            <span className="category-name">{menu.label}</span>
                                                        </a>
                                                    </li>)
                                                }
                                            })
                                    })}
                                </ul>
                            </nav>
                            {/********************* Category Menu End *********************/}
                        </div>
                    </div>
                    <Search />
                    <section className="common-banner ds-banner">
                        <div className="container">
                            <div className="common-banner-content">
                                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                    <h1>{mainUrl && mainUrl === "?alerts" ? "Alerts" : "Release Notes"}</h1>
                                </ScrollAnimation>
                                <div className="resource-wrap">
                                    {
                                        list && list.length ? list.map((each, key) => {
                                            let content = each && each.content && each.content.rendered && (each.content.rendered).substring(3, (each.content.rendered).length - 5);
                                            let name = each && each.title && each.title.rendered ? each.title.rendered : "";
                                            let date = each && each.date ? moment(each.date).format('ll') : "";
                                            let wordpress = each && each._embedded && each._embedded['wp:term'];
                                            if (key < 4 || viewAll) {
                                                return (
                                                    <ScrollAnimation animateIn='fadeIn' animateOnce={true} key={key}>
                                                        <div className="resource-box" >
                                                            <div className="category-show">
                                                                {wordpress && wordpress.length ? wordpress.map((cat, id) => {
                                                                    let name = cat && cat[0] && cat[0].name ? cat[0].name : "";
                                                                    let color = cat && cat[0] && cat[0].acf && cat[0].acf.category_color ? cat[0].acf.category_color : "";
                                                                    return (<span className="category" style={{ "background-color": color }} key={id}>
                                                                        {name}
                                                                    </span>
                                                                    )
                                                                }) : null}
                                                            </div>
                                                            <h6>{name}</h6>
                                                            <span className="date">{date}</span>
                                                            <div className="resource-desc">
                                                                <p><ReadMoreAndLess
                                                                    charLimit={300}
                                                                    readMoreText="CONTINUE READING"
                                                                    readLessText="LESS READING"
                                                                >{content}</ReadMoreAndLess></p>
                                                                <div className={content && content.length < 300 ? "more-share-sec more-share-sec-mt" : "more-share-sec"}>
                                                                    {/* <a href="#" className="continue-read-btn">CONTINUE READING</a> */}
                                                                    <a href="mailto:?Subject=X music&amp;Body=" className="share">
                                                                        <span className="share">SHARE</span>
                                                                        <span className="share-icon"><img src={shareicon} alt="Share" /></span>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ScrollAnimation>
                                                )
                                            }
                                        })
                                            : null
                                    }

                                </div>
                                {list && list.length > 4 && !viewAll ? <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                        <div className="view-all-sec">
                                            <a href="javascript:;" className="view-all-btn" onClick={() => setViewAll(true)}>{viewAll === false ? 'VIEW ALL' : ''}</a>
                                        </div>
                                    </ScrollAnimation> : ""}
                            </div>
                        </div>
                        {viewAll ? <div className="row">
                            <div className="col-md-12">
                                <Pagination showLessItems pageSize={4} current={page} total={total} onChange={(e) => resources(e, mainUrl)} locale={true} />
                            </div>
                        </div> : ""}
                    </section>
                </Layout> : <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div>}
            <Modal show={resoucePopUp} id="filterModal" onHide={closeModal}>
                <Modal.Body>
                    <div className="filter-modal-wrap">
                        <div className="filter-category-wrap">
                            <div className="filter-platform">
                                <h6>PLATFORM</h6>
                                <form>
                                    <div className="checkbox-listing">
                                        {mainMenu && mainMenu.length && mainMenu.map((name) => {
                                            if (name && name.id === "dGVybTo1Mg==") {
                                                return name && name.menuItems && name.menuItems.nodes && name.menuItems.nodes.map((each, id) => {
                                                    each.checked = each.checked ? true : false;
                                                    return (<div className="custom-checkbox-group" key={id}>
                                                        <label className="custom-checkbox">{each.label}
                                                            <input type="checkbox" checked={each.checked} onChange={() => selecCheckBox(id, "platform")} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>)
                                                })
                                            }
                                        })}
                                    </div>
                                </form>
                            </div>

                            <div className="filter-category">
                                <h6>CATEGORY</h6>
                                <form>
                                    <div className="checkbox-listing">
                                        {mainMenu && mainMenu.length && mainMenu.map((name) => {
                                            if (name && name.id === "dGVybTo1Mw==") {
                                                return name && name.menuItems && name.menuItems.nodes && name.menuItems.nodes.map((each, id) => {
                                                    each.checked = each.checked ? true : false;
                                                    return (<div className="custom-checkbox-group" key={id}>
                                                        <label className="custom-checkbox">{each.label}
                                                            <input type="checkbox" checked={each.checked} onChange={() => selecCheckBox(id, "category")} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>)
                                                })
                                            }
                                        })}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default Resource


//******************** Recommend Slider ************************//
